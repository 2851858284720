import styled from "styled-components";
import { respondTo } from "../../styles";
import { PanelListItemMixin } from "../../styles/mixins";
import { ImageWrapperCircle } from "./ImageWrapper";
//import { respondTo } from "../../styles";

export const PanelList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  counter-reset: ${(props) =>
    props.counterName || "none"}; // for counter component in lists

  ${respondTo.lg`
    flex-direction: row;
    height: ${(props) => props.height || "auto"};
  `}
`;
export const PanelListItem = styled.li`
  --marginRight: 30px;
  --listLength: ${(props) => props.listLength};
  background-color: white;
  height: 100%;
  display: block;
  margin-bottom: 12px;

  ${respondTo.lg`
    // --- Dynamic list item width, could be hardcoded value or array.length
    width: calc(
      100% / var(--listLength) - (var(--marginRight) * (var(--listLength) - 1)) /
        var(--listLength)
    );
    margin-right: var(--marginRight);
    background-color: ${(props) => props.bgColor || "white"};
      &:last-of-type {
      margin-right: 0;
    }
    margin-bottom: 0;
  `}
`;

export const PanelListItemText = styled.div`
  overflow: hidden;

  h3 {
    font-size: 22px;
    line-height: 30px;
    color: var(--pmbBlack);
    margin-bottom: 20px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const PanelListItemContent = styled.div`
  ${PanelListItemMixin.theme}

  ${ImageWrapperCircle} {
    margin-bottom: 24px;
  }
`;

export const PanelListItemLink = styled.a`
  ${PanelListItemMixin.theme}
  text-decoration: none;

  ${ImageWrapperCircle} {
    margin-bottom: 24px;
  }
`;
