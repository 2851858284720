import React, { useState } from "react";
import styled from "styled-components";
import {
  CustomButton,
  CustomButtonContainer,
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  TextInput,
} from "../../../components/common";
import { PageHeaderImageSign } from "../../../components/common/PageHeaderImage";
import {
  LoginArticle,
  LoginArticleForm,
  LoginArticleFormsContainer,
  LoginInputWrapper,
} from "../../../components/Login";
import { setSessionPassword } from "../utils/utils";

const AdminLoginWrapper = styled(LoginInputWrapper)`
  left: -230px;

  label {
    width: 200px;
  }
`;

const PasswordProtect = () => {
  const [password, setPassword] = useState("");

  const onSubmit = (event) => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <PageSectionWrapper>
      <PageSection bgColor="var(--loginBg)">
        <PageSectionBackdrop bgColor="var(--loginBg)">
          <LoginArticle>
            <LoginArticleFormsContainer>
              <PageHeaderImageSign />
              <h1>Admin</h1>
              <LoginArticleForm onSubmit={onSubmit} marginBottom="20px">
                <AdminLoginWrapper>
                  <label htmlFor="password">Ange lösenord</label>
                  <TextInput
                    name="password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    customWidth="325px"
                  />
                </AdminLoginWrapper>
                <CustomButtonContainer width="325px">
                  <CustomButton type="submit">Logga in</CustomButton>
                </CustomButtonContainer>
              </LoginArticleForm>
            </LoginArticleFormsContainer>
          </LoginArticle>
        </PageSectionBackdrop>
      </PageSection>
    </PageSectionWrapper>
  );
};

export default PasswordProtect;
