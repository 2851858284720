import React from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { respondTo } from "../../styles";
import PmbLogoCloud from "../../images/PMB_footer_cloud_logo.png";
import PmbLogo from "../../images/PMB_Logo.png";
import FacebookIcon from "../../images/Facebook.png";
import InstagramIcon from "../../images/Instagram.png";
import ArvsfondenLogo from "../../images/PMB_allmana_pensionsfonden_logo.png";
import { Icon } from "../common";

const FooterEl = styled.footer`
  background-color: var(--infoBg);
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;

  @media print {
    display: none;
  }

  > * {
    margin-bottom: 50px;

    ${respondTo.lg`
      margin-bottom: 0;
    `}
  }

  ${respondTo.lg`
    flex-direction: row;
    justify-content: space-between;
    height: 160px;
    padding-top: 0;
    max-width: 1200px;
  `}

  ${respondTo.xl`
    max-width: 1400px;
  `}

  p, a {
    color: var(--pmbFooterBlue);
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    line-height: 26px;

    ${respondTo.lg`
      font-size: 20px;
    `}
  }
`;

const FooterHomeLink = styled(GatsbyLink)`
  background-image: url(${PmbLogo});
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  height: 70px;
  width: 200px;

  ${respondTo.lg`
    background-image: url(${PmbLogoCloud});
    background-size: 360px auto;
    top: -30px;
    height: 160px;
    width: 300px;
  `}
`;

const FooterSocialMedia = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 60px 20px;

  a {
    height: 100%;
    width: 100%;
    display: flex;

    &:first-of-type {
      justify-content: flex-end;
      padding-right: 10px;
    }
    &:last-of-type {
      justify-content: flex-start;
      padding-left: 10px;
    }
  }

  p {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    text-align: center;

    ${respondTo.lg`
      transform: rotate(-5deg);
    `}
  }
`;

const FooterInfoLinks = styled.div`
  display: flex;
  flex-direction: column;

  a {
    text-decoration: underline;

    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;

const FooterSponsorLink = styled.a`
  background-image: url(${ArvsfondenLogo});
  background-repeat: no-repeat;
  background-size: contain;
  height: 43px;
  width: 179px;
  display: inline-block;
  margin-right: 20px;
`;

const Footer = () => {
  return (
    <FooterEl>
      <FooterContent>
        <FooterHomeLink to="/">
          <span className="sr-only">Tillbaka till startsidan</span>
        </FooterHomeLink>
        <FooterSocialMedia>
          <a
            href="https://www.facebook.com/pluggamedbilder"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={FacebookIcon} />
            <span className="sr-only">Till vår Facebook-sida</span>
          </a>
          <a
            href="https://www.instagram.com/pluggamedbilder/?hl=sv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={InstagramIcon} />
            <span className="sr-only">Till vår Insatgram-sida</span>
          </a>
          <p>Följ oss på sociala medier</p>
        </FooterSocialMedia>
        <FooterInfoLinks>
          <GatsbyLink to="/terms">Köpvillkor</GatsbyLink>
          <GatsbyLink to="/gdpr">GDPR-hantering</GatsbyLink>
        </FooterInfoLinks>
        <FooterSponsorLink
          href="https://www.arvsfonden.se/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">Till vår sponsor</span>
        </FooterSponsorLink>
      </FooterContent>
    </FooterEl>
  );
};

export default Footer;
