import React from "react";
import styled, { keyframes } from "styled-components";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const popIn = keyframes`
from {
  opacity: 0;
  transform: scale(1.5) rotate(45deg);
  
}
to {
  opacity: 1;
  transform: scale(1) rotate(45deg);
}
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 12px 20px;
  font-size: 24px;
  padding: ${(props) => (props.labelPadding ? props.labelPadding : "0")};

  span {
    font-size: 16px;
    font-weight: 400;
    ${(props) =>
      props.block
        ? "display: block; padding-top: 12px;"
        : "display: block; padding-top: 12px;"}
  }
`;

const Indicator = styled.div`
  background-color: #fff;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: -30px;

  ${Label}:hover & {
    outline: 1px solid #000;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }

  ${Input}:checked + &:after {
    display: block;
    height: 17px;
    width: 8px;
    left: 7px;
    bottom: 5px;
    border-bottom: 3px solid #000;
    border-right: 3px solid #000;
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: #e6e6e6;
  }
`;

const CustomRadioAndCheckboxPlaceholder = styled(Indicator)`
  pointer-events: none;
  opacity: 0.6;
  background: #e6e6e6;

  ${Input}:checked + &:after {
    content: none;
  }
`;

const CustomRadioAndCheckbox = ({
  value,
  onChange,
  name,
  id,
  label,
  disabled,
  checked,
  dataAttr,
  type,
  labelPadding,
  placeholderComponent,
  labelSpan,
}) => {
  return (
    <Label htmlFor={id} disabled={disabled} labelPadding={labelPadding}>
      {label} {labelSpan && <span>{labelSpan}</span>}
      <Input
        data-option={dataAttr}
        id={id}
        type={type}
        role={type}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      {placeholderComponent ? (
        <CustomRadioAndCheckboxPlaceholder />
      ) : (
        <Indicator />
      )}
    </Label>
  );
};
export default CustomRadioAndCheckbox;
