import styled, { css } from "styled-components";
import { respondTo } from "../../styles";
import { Link as GatsbyLink } from "gatsby";
import WoodBg from "../../images/PMB_get_started_wood.png";

export const LoginLogoutAccountStyle = css`
  transform: rotate(-4deg);
  background-color: var(--creamWhite);
  margin-bottom: 20px;
  border-radius: 67px;
  border: 6px solid var(--coralGreen);
  color: var(--coralGreen);
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
  padding: 20px 35px;
  justify-content: flex-start;
  margin-bottom: auto;
`;

export const StyledLink = styled(GatsbyLink)`
  text-decoration: none;
  text-transform: uppercase;
  color: var(--creamWhite);
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.25);
  font-size: 18px;
  font-weight: 700;
  padding: 15px 0;
  z-index: 4;

  ${respondTo.md`
    background-color: var(--coralGreen);
    box-shadow: 12px 11px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border-radius: 67px;
    border: 6px solid #fffdf8;
    justify-content: flex-start;
    padding: 27px 35px;
    text-transform: none;
    font-size: 20px;
    width: 340px;
  `}

  &[href='/login'],
  &[href='/account'] {
    ${LoginLogoutAccountStyle}

    ${respondTo.md`
      margin-bottom: 100px;
    `}
  }

  &[href="/instructions"] {
    ${respondTo.md`
      transform: rotate(-1deg);
      position: relative;
      left: -40px;`}
  }

  &[href="/about"] {
    ${respondTo.md`
      transform: rotate(-7deg);
      margin-bottom: 50px;
      position: relative;
      left: -20px;`}
  }

  &[href="/film"] {
    ${respondTo.md`
      transform: rotate(5deg);
      position: relative;
      left: -55px;
    `}
  }

  &[href="https://www.logistikteamet.se/pluggamedbilder"] {
    ${respondTo.md`
      background-image: url(${WoodBg});
      background-repeat: no-repeat;
      background-size: contain;
      height: 126px;
      position: relative;
      left: -60px;
      padding-left: 50px;
      transform: rotate(3deg);
    `}
  }
`;
