import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { config } from "../firebase/config";

let instance = null;

// create instance if window object is available
// if window is available with instance, return that
// else create and return new instance
export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}
