import styled, { css } from "styled-components";

export const UnorderedListStyle = css`
  list-style: none;
`;

export const BulletListItemStyle = css`
  font-size: 16px;
  line-height: 22px;
  padding-left: 20px;
  position: relative;
  margin-bottom: 16px;

  &:before {
    content: "";
    position: absolute;
    height: 11px;
    width: 11px;
    background-color: var(--buttonBg);
    border-radius: 100%;
    top: 5px;
    left: 0%;
  }
`;

export const PageUnorderedList = styled.ul`
  ${UnorderedListStyle}
`;

export const PageListItem = styled.li`
  ${BulletListItemStyle}
`;
