import React, { useState, useEffect, useCallback } from "react";
import useFirebase from "../../../firebase/useFirebase";
import { debounce } from "../../utils/helpers";
import {
  HomeLink,
  DesktopNavContainer,
  LinksContainer,
  StyledLink,
  LoginAndAccountLinkContainer,
  NavMobileMenu,
} from "./partials";

const Nav = () => {
  const [user, setUser] = useState();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const firebase = useFirebase();

  useEffect(() => {
    if (!firebase) return;
    return firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, [firebase]);

  const handleScroll = useCallback(
    debounce(() => {
      const currentScrollPos = window.pageYOffset;
      setVisible(
        prevScrollPos > currentScrollPos || currentScrollPos < prevScrollPos
      );
      setPrevScrollPos(currentScrollPos);
    }, 10),
    [prevScrollPos]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <>
      <HomeLink to="/">
        <span className="sr-only">Tillbaka till startsidan</span>
      </HomeLink>
      <DesktopNavContainer
        onMouseEnter={() => setVisible(true)}
        visible={visible}
      >
        <LinksContainer>
          <LoginAndAccountLinkContainer>
            {!!user ? (
              <StyledLink to="/account">Mitt konto</StyledLink>
            ) : (
              <StyledLink to="/login">Logga in</StyledLink>
            )}
          </LoginAndAccountLinkContainer>
          <StyledLink to="https://www.logistikteamet.se/pluggamedbilder">
            Beställ här
          </StyledLink>
          <StyledLink to="/instructions">Så funkar det</StyledLink>
          {/* <StyledLink to="/about">Om oss</StyledLink> */}
          <StyledLink to="/film">Föreläsningar</StyledLink>
        </LinksContainer>
      </DesktopNavContainer>
      {/* <NavMobileMenu /> */}
    </>
  );
};

export default Nav;
