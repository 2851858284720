import styled from "styled-components";

export const Icon = styled.i`
  height: ${(props) => props.size || "40px"};
  width: ${(props) => props.size || "40px"};
  background-color: ${(props) => props.bgColor || "transparent"};
  border-radius: ${(props) => props.borderRadius || "0"};
  border: none;
  background-image: url(${(props) => props.icon || ""});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: ${(props) => props.top || "unset"};
  right: ${(props) => props.right || "unset"};
`;

export const IconButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: ${(props) => props.bgColor || "transparent"};
  border-radius: ${(props) => props.borderRadius || "0"};
  border: none;
  background-image: url(${(props) => props.icon || ""});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: ${(props) => props.top || "unset"};
  right: ${(props) => props.right || "unset"};
`;
