import styled from "styled-components";
import { respondTo } from "../../styles";
import { PageSectionArticle } from "../common";

export const LoginArticle = styled(PageSectionArticle)`
  display: none;
  min-height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: 80px;

  ${respondTo.lg`
    display: flex;
  `}

  h1 {
    color: var(--creamWhite);
    margin: 0 0 30px;
    font-size: 40px;
  }
`;

export const LoginArticleFormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 400px;
  align-items: flex-start;
`;

export const LoginArticleForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

export const LoginArticleNoShow = styled(PageSectionArticle)`
  min-height: 100vh;
  padding: 200px 25px;
  ${respondTo.lg`
    display: none;
  `}
`;
