import styled from "styled-components";
import { respondTo } from "../../styles";

export const ImageWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
`;

export const ImageWrapperSub = styled.div`
  margin: 20px auto 0;
  overflow: hidden;
`;

export const ImageWrapperSquare = styled(ImageWrapper)`
  width: ${(props) => props.size || "100px"};
  height: ${(props) => props.size || "100px"};
`;

export const ImageWrapperCircle = styled(ImageWrapper)`
  width: ${(props) => props.size || "100px"};
  height: ${(props) => props.size || "100px"};
  min-height: ${(props) => props.size || "100px"};
  border-radius: 100px;
`;

export const ImageWrapperRectangle = styled(ImageWrapper)`
  // 9:5-ratio
  width: ${(props) => props.smallWidth || "270px"};
  height: ${(props) => props.smallHeight || "190px"};

  ${respondTo.md`
    width: ${(props) => props.mediumWidth || "270px"};
  height: ${(props) => props.mediumHeight || "190px"};
  `}

  ${respondTo.xl` 
    width: ${(props) => props.width || "360px"};
    height: ${(props) => props.height || "200px"};  
  `}
`;
