import styled from "styled-components";
import { respondTo } from "../../styles";

// set counter-reset on parent element
export const CounterIcon = styled.i`
  background-color: ${(props) => props.bgColor || "var(--pmbBlack)"};
  height: 84px;
  width: 84px;
  border-radius: 100px;
  grid-column: 1 / 2;
  align-self: center;
  justify-self: center;

  ${respondTo.lg`
    align-self: auto;
    justify-self: auto;
  `}

  &:before {
    counter-increment: ${(props) => props.counterName || "none"};
    content: ${(props) => `counter(${props.counterName}) "."` || ""};
    font-family: "Spicy Rice", cursive;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    color: white;
    padding-left: 20px;
    letter-spacing: 3px;
  }
`;
