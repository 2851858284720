import styled from 'styled-components';

export const HamburgerMenu = styled.div`
  transform: rotate(-30deg);
  position: absolute;
  top: 25px;
  left: 25px;

  span {
    display: block;
    width: 25px;
    height: 4px;
    background-color: white;
    border-radius: 100px;
    margin-bottom: 4px;
    transition: 0.4s;
    transform-origin: 0% 0%;

    &:nth-child(1) {
      ${(props) =>
        props.show
          ? `transform: rotate(45deg) translate(-2px, -2px);`
          : `transform: rotate(0deg) translate(0, 0);`}
    }

    &:nth-child(2) {
      ${(props) =>
        props.show
          ? `transform: rotate(0deg) scale(0.2, 0.2); opacity: 0;`
          : `transform: rotate(0deg) scale(1, 1); opacity: 1;`}
    }

    &:nth-child(3) {
      ${(props) =>
        props.show
          ? `transform: rotate(-45deg) translate(-1px, -2px);`
          : `transform: rotate(0deg) translate(0, 0);`}
    }
  }
`;
