import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import { respondTo } from ".././../../styles/respondTo";
import PmbLogo from "../../../images/PMB_Logo.png";

export const HomeLink = styled(GatsbyLink)`
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 140;
  z-index: 4;

  ${respondTo.md`
  width: 200px;
`}

  &:after {
    position: absolute;
    content: "";
    background-image: url(${PmbLogo});
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    width: 100%;
    top: 50px;
    left: 5px;

    ${respondTo.md`
      height: 80px;
      top: 5px;
  `}
  }
`;
