import React, { useState } from "react";
import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";
import { StyledLink } from "../../common";
import { HamburgerMenu } from "./HamburgerMenu";
import { MobileMenuButton } from "./MobileMenuButton";

const MobileMenu = styled.div`
  background-color: #008dab;
  position: fixed;
  bottom: 0;
  transform: translatex(300px) translatey(300px);
  height: 300px;
  padding: 40px 0 65px;
  width: 100%;
  z-index: 4;
  transition: all 0.2s ease-in-out;

  border-top: 6px solid #fffdf8;
  border-left: 6px solid #fffdf8;
  border-radius: 67px 0 0 0;
  ${(props) =>
    props.show
      ? `transform: translatex(0) translatey(0);`
      : `transform: translatex(300px) translatey(300px);`}

  ${respondTo.md`
      display: none;

      > * {
        display: none;
      }
    `}
`;

export const NavMobileMenu = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const mobileMenuHandler = () => setShowMobileMenu(!showMobileMenu);
  return (
    <>
      <MobileMenuButton onClick={mobileMenuHandler} show={showMobileMenu}>
        <HamburgerMenu show={showMobileMenu}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </HamburgerMenu>
      </MobileMenuButton>
      {showMobileMenu && (
        <MobileMenu show={showMobileMenu}>
          <StyledLink
            to="/instructions"
            onClick={() => setShowMobileMenu(false)}
          >
            Så funkar det
          </StyledLink>
          {/* <StyledLink to="/about" onClick={() => setShowMobileMenu(false)}>
            Om oss
          </StyledLink> */}
          <StyledLink to="/film" onClick={() => setShowMobileMenu(false)}>
            Föreläsningar
          </StyledLink>
          <StyledLink
            to="https://www.logistikteamet.se/pluggamedbilder"
            onClick={() => setShowMobileMenu(false)}
          >
            Beställ här
          </StyledLink>
          <StyledLink to="/" onClick={() => setShowMobileMenu(false)}>
            Till Startsidan
          </StyledLink>
        </MobileMenu>
      )}
    </>
  );
};
