import styled from "styled-components";
import { respondTo } from "../../../styles/respondTo";

export const MobileMenuButton = styled.button`
  background-color: #28b8c1;
  color: white;
  border-radius: 67px;
  border: 6px solid #fffdf8;
  box-shadow: 12px 11px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 3px 1px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: 700;
  transform: rotate(30deg);
  position: fixed;
  bottom: -10px;
  right: -22px;
  height: 85px;
  width: 100px;
  transition: all 0.1s linear;
  z-index: 100;

  ${(props) =>
    props.show
      ? `border: 0px solid #fffdf8; background-color: transparent;`
      : `border: 6px solid #fffdf8; background-color: #28b8c1;`}

  ${respondTo.md`
    display: none;
  `}
`;
