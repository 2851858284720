import styled from "styled-components";

export const CustomButton = styled.button`
  height: 75px;
  width: ${(props) => props.customWidth || "255px"};
  border: 6px solid;
  border-color: ${(props) => props.borderColor || "var(--coralGreen)"};
  color: ${(props) => props.textColor || "var(--coralGreen)"};
  background-color: ${(props) => props.bgColor || "var(--creamWhite)"};
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  box-shadow: 12px 11px 8px rgba(0, 0, 0, 0.1);
  border-radius: 67px;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 12px 17px 8px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    background-color: #ccc;
    color: gray;
    border-color: gray;
  }
`;

export const CustomButtonSmall = styled.button`
  border: 2px solid #1e1e1e;
  padding: 10px 44px;
  background-color: transparent;
  background-color: ${(props) => props.btnColor || "transparent"};
  color: ${(props) => props.btnTextColor || "black"};
  border-radius: 67px;
  justify-self: center;
  font-size: 24px;

  &:disabled {
    opacity: 0.3;
  }
`;

export const CustomButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width || "auto"};
`;
