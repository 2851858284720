import styled, { css } from "styled-components";
import { respondTo, breakpoints } from "../../styles";
import { PageHeaderImage } from "./PageHeaderImage";
import { PanelList } from "./PanelList";
import { ImageWrapperRectangle } from "./ImageWrapper";

export const PageSectionWrapper = styled.div`
  position: relative;
`;

export const PageSectionBackdrop = styled.div`
  background-color: ${(props) => props.bgColor || "var(--pmbMystic)"};
  position: relative;
  padding-bottom: ${(props) => props.paddingBottom || "20px"};

  ${respondTo.lg`
    background-color: ${(props) => props.bgColor || "var(--pmbJungleMist)"};
    border-radius: 0 0 78% 78% / 175px;
    width: calc(100% + 300px);
    left: -150px;
  `}
`;

export const PageSection = styled.section`
  background-color: ${(props) => props.bgColor || "var(--pmbMystic)"};
  position: relative;

  &:last-child {
    ${PageSectionBackdrop} {
      border-radius: unset;
      padding-bottom: 90px;
    }
  }

  ${respondTo.lg`
    padding: 0;
    background-color: ${(props) => props.bgColor || "var(--pmbJungleMist)"};

    &:nth-of-type(even) {
      background-color: ${(props) => props.bgColor || "var(--pmbMystic)"};
    }
  `}
`;

export const PageSectionChildElement = css`
  margin: 0;
  position: relative;
  padding: 0 20px;
  max-width: 900px;

  ${respondTo.md`
    padding: 0 40px;
  `}

  ${respondTo.lg`
    margin: 0px auto 0 200px;
    padding: 0;
    max-width: ${breakpoints.lg};
  `}

  ${respondTo.xxl`
    margin: 0 auto;
  `}
`;

export const PageSectionChildElementGrid = css`
  ${respondTo.lg`
    display: grid;
    grid-template-columns: 320px auto;
    grid-template-rows: auto auto;
    column-gap: 100px;
    padding: 120px 0 0;
  `}
`;

export const PageSectionArticle = styled.article`
  ${PageSectionChildElement}
`;

export const PageSectionHeader = styled.header`
  ${PageSectionChildElement}
  ${PageSectionChildElementGrid}
  ${respondTo.md`
    ${PageHeaderImage} {
      margin: 0 auto;
    }
  `}

  ${respondTo.lg`
    ${PanelList}, h2 {
      grid-column: 1 / 4;
    }

    ${ImageWrapperRectangle} {
      grid-column: 2 / 3;
    }
  `}
`;

export const PageSectionHeaderText = styled.div`
  grid-column: 2 / 4;
  max-width: 550px;
  margin-bottom: 50px;

  ${respondTo.xl`
    max-width: 740px;
  `}

  h1 {
    margin-bottom: 16px;
  }
`;
