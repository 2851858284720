import React, { useState } from "react";
import { Helmet } from "react-helmet";
import GlobalStyle from "../../styles/globalStyle";
import Nav from "../Navigation/Nav";
import Footer from "../Footer";
import UserContext from "../../context";

const Layout = ({ children, path }) => {
  const [subscriber, setSubscriber] = useState({});

  return (
    <UserContext.Provider value={{ subscriber, setSubscriber }}>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Spicy+Rice&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      {path === "/account/" ||
      path === "/play/" ||
      path === "/admin-user/" ||
      path === "/confirmation/" ||
      path === "/checkout/" ? (
        ""
      ) : (
        <Nav />
      )}
      <h1 className="sr-only">Havsäventyret, plugga med bilder</h1>
      <main>{children}</main>

      <Footer />
    </UserContext.Provider>
  );
};

export default Layout;
