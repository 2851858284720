import styled from "styled-components";
import { respondTo } from "../../../styles";

export const DesktopNavContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 250px;
  position: fixed;
  z-index: 2;
  top: 20px;
  padding: 8px 0;
  height: 100%;
  right: -100px;
  right: ${(props) => (props.visible ? "-100px" : "-180px")};
  z-index: 5;
  display: none;
  transition: right 0.3s;
  ${respondTo.md`
    display: flex;
  `}
  ${respondTo.lg`
    justify-content: space-between;
  `}
`;
