import styled from "styled-components";
import { respondTo } from "../../../styles";

export const LoginAndAccountLinkContainer = styled.div`
  display: none;
  margin-bottom: auto;

  ${respondTo.lg`
    display: block;
  `}
`;
