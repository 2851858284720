import styled from "styled-components";
import { respondTo } from "../../styles";
import OctoLogo from "../../images/PMB_octopus.png";
import SignLogo from "../../images/PMB_sign_logo.png";

export const PageHeaderImage = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${OctoLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 0 30px;

  ${respondTo.md`
  width: 311px;
  height: 279px;
  margin: 0 42px 0 0;`}
`;

export const PageHeaderImageSign = styled.div`
  background-image: url(${SignLogo});
  background-repeat: no-repeat;
  background-size: contain;
  height: 141px;
  width: 325px;
  margin: 0 0 50px;
`;
