// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-extend-subscription-js": () => import("./../../../src/pages/extend-subscription.js" /* webpackChunkName: "component---src-pages-extend-subscription-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgotPassword.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-play-js": () => import("./../../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-user-admin-js": () => import("./../../../src/pages/user-admin.js" /* webpackChunkName: "component---src-pages-user-admin-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-account-page-js": () => import("./../../../src/templates/account-page.js" /* webpackChunkName: "component---src-templates-account-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-film-page-js": () => import("./../../../src/templates/film-page.js" /* webpackChunkName: "component---src-templates-film-page-js" */),
  "component---src-templates-gdpr-page-js": () => import("./../../../src/templates/gdpr-page.js" /* webpackChunkName: "component---src-templates-gdpr-page-js" */),
  "component---src-templates-get-started-page-js": () => import("./../../../src/templates/get-started-page.js" /* webpackChunkName: "component---src-templates-get-started-page-js" */),
  "component---src-templates-instructions-page-js": () => import("./../../../src/templates/instructions-page.js" /* webpackChunkName: "component---src-templates-instructions-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-start-page-js": () => import("./../../../src/templates/start-page.js" /* webpackChunkName: "component---src-templates-start-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-theboattrip-page-js": () => import("./../../../src/templates/theboattrip-page.js" /* webpackChunkName: "component---src-templates-theboattrip-page-js" */)
}

